<template>
	<div>
		<LoadingScreen v-if="loading_screen" />
		<div id="printMe" class="px-1 z-50" v-if="toggle_cetak">
			<div class="text-center text-sm font-weight-bold">{{ cabang.kode_cabang }} {{ cabang.nama }}</div>
			<div class="text-center text-sm font-weight-bold">{{ cabang.alamat }}</div>
			<div class="text-center text-sm font-weight-bold">No Izin Klinik : {{ cabang.sio }}</div>
			<div class="text-center text-sm font-weight-bold">Phone : {{ cabang.telepon }}</div>

			<hr style="border: 1px solid #000;">
      		<div class="text-center text-sm font-weight-bold">*** KWITANSI PEMBAYARAN ***</div>
			<hr style="border: 1px solid #000;">

			<small>No.Kwitansi : {{ transaksi.nomor_kwitansi }} - Tgl : {{ transaksi.created_at }}</small><br>
			<small>Waktu Cetak : {{ tanggal }}</small><br>
			<small>Pelanggan : {{ transaksi.nomor_rekam_medis }} - {{ transaksi.nama_pasien }}</small><br>
			<small>Dokter : {{ transaksi.nama_dokter }}</small><br>
			<small>Kasir : {{ transaksi.user?.name }} </small><br>
			<small>Jenis Pelanggan : {{ transaksi.kunjungan?.poli?.nama && `POLI ${transaksi.kunjungan.poli.nama}` }} - {{ transaksi.kunjungan?.layanan?.nama && `LAYANAN ${transaksi.kunjungan.layanan.nama}` }}</small><br>

			<small><hr style="border: 1px dashed #000;"></small>

			<div v-if="jenis == 'detail'">
				<div v-if="produk > 0" class="d-flex justify-content-between">
					<small class="font-weight-bold">Resep/Obat</small>
					<span>{{ format_nominal(produk) }}</span>
				</div>

				<div v-if="tindakan.length > 0">
					<small class="font-weight-bold">Tindakan</small>
					<div class="row" v-for="(row, index) in tindakan" :key="row.id">
						<div class="col-6">
							<small>{{ index+1 }}. {{ row.nama }}</small>
						</div>
						<div class="col-2">
							<small>{{ format_nominal(row.harga_awal) }}</small>
						</div>
						<div class="col-2" v-if="row.diskon <= 100">
							<small>{{ row.diskon }} %</small>
						</div>
						<div class="col-2" v-else>
							<small>Rp. {{ row.diskon }}</small>
						</div>
						<div class="col-2">
							<small class="text-right">{{ format_nominal(row.harga_akhir) }}</small>
						</div>
					</div>
				</div>

				<div v-if="administrasi.length > 0">
					<small class="font-weight-bold">Administrasi</small>
					<div class="row" v-for="(row, index) in administrasi" :key="row.id">
						<div class="col-6">
							<small>{{ index+1 }}. {{ row.nama }}</small>
						</div>
						<div class="col-2">
							<small>{{ format_nominal(row.harga_awal) }}</small>
						</div>
						<div class="col-2" v-if="row.diskon <= 100">
							<small>{{ row.diskon }} %</small>
						</div>
						<div class="col-2" v-else>
							<small>Rp. {{ row.diskon }}</small>
						</div>
						<div class="col-2">
							<small class="text-right">{{ format_nominal(row.harga_akhir) }}</small>
						</div>
					</div>
				</div>

				<div v-if="alat_kesehatan.length > 0">
					<small class="font-weight-bold">Alat Kesehatan</small>
					<div class="row" v-for="(row, index) in alat_kesehatan" :key="row.id">
						<div class="col-6">
							<small>{{ index+1 }}. {{ row.nama }}</small>
						</div>
						<div class="col-2">
							<small>{{ format_nominal(row.harga_awal) }}</small>
						</div>
						<div class="col-2" v-if="row.diskon <= 100">
							<small>{{ row.diskon }} %</small>
						</div>
						<div class="col-2" v-else>
							<small>Rp. {{ row.diskon }}</small>
						</div>
						<div class="col-2">
							<small class="text-right">{{ format_nominal(row.harga_akhir) }}</small>
						</div>
					</div>
				</div>
			</div>

			<div v-if="jenis == 'detail' && cabangLogin.tipe == 'kecantikan'">
				<div class="row" v-for="(row, index) in transaksi_detail" :key="row.id">
					<div class="col-6">
						<small>{{ index+1 }}. {{ row.nama }}</small>
					</div>
					<div class="col-2">
						<small>{{ format_nominal(row.harga_awal) }}</small>
					</div>
					<div class="col-2" v-if="row.diskon <= 100">
						<small>{{ row.diskon }} %</small>
					</div>
					<div class="col-2" v-else>
						<small>Rp. {{ row.diskon }}</small>
					</div>
					<div class="col-2">
						<small class="text-right">{{ format_nominal(row.harga_akhir) }}</small>
					</div>
				</div>
			</div>

			<div v-if="jenis == 'not_detail'">
				<div class="row" v-for="(row, index) in transaksi_detail" :key="row.id">
					<div class="col-6">
						<small>{{ index+1 }}. {{ row.nama }}</small>
					</div>
					<div class="col-4">
						<small class="text-right">{{ format_nominal(row.harga_akhir) }}</small>
					</div>
				</div>
			</div>

			<hr style="border: 1px dashed #000;">	

			<div class="text-sm"> 
				<div class="row">
					<div class="col">
						<small>Sub Total: </small>
					</div>
					<div class="col">
						<small class="text-right">{{ format_nominal(transaksi_detail.reduce((preVal, curVal) =>  preVal + curVal.harga_akhir, 0)) }}</small>
					</div>
				</div>
				<div class="row" v-if="transaksi.biaya_lainnya > 0">
					<div class="col">
						<small>Biaya Lainnya: </small>
					</div>
					<div class="col">
						<small class="text-right">{{ format_nominal(transaksi.biaya_lainnya) }}</small>
					</div>
				</div>
				<div class="row" v-if="transaksi.diskon + transaksi.diskon_voucher > 0">
					<div class="col">
						<small>Diskon: </small>
					</div>
					<div class="col">
						<small class="text-right">{{ format_nominal(transaksi.diskon + transaksi.diskon_voucher) }}</small>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<small>Total: </small>
					</div>
					<div class="col">
						<small class="text-right">{{ format_nominal(transaksi.total_bayar) }}</small>
					</div>
				</div>
				<div class="row" v-if="transaksi.tunai > 0">
					<div class="col">
						<small>Pembayaran > Tunai: </small>
					</div>
					<div class="col">
						<small class="text-right">{{ format_nominal(transaksi.tunai) }}</small>
					</div>
				</div>
				<div class="row" v-if="transaksi.kartu_debit > 0">
					<div class="col">
						<small>Pembayaran > Kartu Debit > {{ transaksi.metode_pembayaran_kartu_debit }}: </small>
					</div>
					<div class="col">
						<small class="text-right">{{ format_nominal(transaksi.kartu_debit) }}</small>
					</div>
				</div>
				<div class="row" v-if="transaksi.kartu_kredit > 0">
					<div class="col">
						<small>Pembayaran > Kartu Kredit > {{ transaksi.metode_pembayaran_kartu_kredit }}: </small>
					</div>
					<div class="col">
						<small class="text-right">{{ format_nominal(transaksi.kartu_kredit) }}</small>
					</div>
				</div>
				<div class="row" v-if="transaksi.transfer > 0">
					<div class="col">
						<small>Pembayaran > Kartu Kredit > {{ transaksi.metode_pembayaran_transfer }}: </small>
					</div>
					<div class="col">
						<small class="text-right">{{ format_nominal(transaksi.transfer) }}</small>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<small>Kembalian: </small>
					</div>
					<div class="col">
						<small class="text-right">{{ format_nominal(transaksi.kembalian) }}</small>
					</div>
				</div>
			</div>		
			
			<hr style="border: 1px solid #000;">
			<small>Terima kasih atas kunjungan Anda</small><br>
    
    	</div>


	</div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
// import router from '@/router'
import { useRoute } from 'vue-router'
import axios from 'axios'
import format_nominal from '@/format_nominal'
import store from '@/store'

import LoadingScreen from '@/components/LoadingScreen'

const cabangLogin = computed(() => store.getters['auth/cabang'])
const route = useRoute()
const transaksi_id = route.params.id
const transaksi = ref([])
const cabang = ref([])
const transaksi_detail = ref([])
const produk = ref(null)
const alat_kesehatan = ref([])
const tindakan = ref([])
const administrasi = ref([])
const toggle_cetak = ref(false)
const loading_screen = ref(true)
const jenis = ref('')

const d = new Date()
let year = d.getFullYear()
let month = d.getMonth() + 1
let day = d.getDate()
let hours = d.getHours()
let minutes = d.getMinutes()
let seconds = d.getSeconds()
const tanggal = ref(`${year}/${month}/${day} ${hours}:${minutes}:${seconds}`)

const getTransaksiPerawatan = async () => {
	let { data } = await axios.get(`api/transaksi_perawatan/getWhereId/${transaksi_id}/${cabangLogin.value.tipe}`)

	transaksi.value = data
	let hari = transaksi.value.created_at.slice(8, 10)
	let bulan = transaksi.value.created_at.slice(5, 7)
	let tahun = transaksi.value.created_at.slice(0, 4)
	transaksi.value.created_at = `${hari}-${bulan}-${tahun}`
	getCabang()
	getTransaksiDetail()
}

const getCabang = async () => {
	let { data } = await axios.get(`api/cabang/getWhereId/${transaksi.value.cabang_id}`)
	
	cabang.value = data
}

const getTransaksiDetail = async () => {
	let { data } = await axios.get(`api/transaksi_perawatan/getDetailWhereId/${transaksi.value.id}/${cabangLogin.value.tipe}`)

	if (data != 'kosong') {
		if(data.jenis == 'detail') {
			if(cabangLogin.value.tipe == 'kecantikan') {
				transaksi_detail.value = data.data
			} else {
				produk.value = data.produk
				alat_kesehatan.value = data.alat_kesehatan
				tindakan.value = data.tindakan
				administrasi.value = data.administrasi
			}
		} else {
			transaksi_detail.value = data.data
		}

		jenis.value = data.jenis
		loading_screen.value = false
		toggle_cetak.value = true
		
		// transaksi_detail.value.diskon = transaksi_detail.value.total_bayar - (transaksi_detail.value.tunai + transaksi_detail.value.kartu_debit + transaksi_detail.value.kartu_kredit + transaksi_detail.value.transfer)
	}
}


onMounted(() => {
	getTransaksiPerawatan()
})
</script>

<style>
#printMe {
	width: 6cm;
	height: auto;
}

@page { 
	margin-top: 0mm; 
}
</style>